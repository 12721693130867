.jumbotron { background-color: transparent; /*color: #fff;*/ /*position: relative;*/ bottom: -300; padding: 8rem 2rem; }
.jumbotron-fluid { padding-right: 0; padding-left: 0; border-radius: 0; }

.purple-separator:after { left: 50%; margin-left: -40px; background-color: #554cb7; }
.center-title { text-align: center; }


.hero-text h1 { letter-spacing: 10px; line-height: 5rem; }

@media (max-width: 767.98px) {
  .hero-text h1 { letter-spacing: 10px; line-height: 5rem; }
}

p { font-size: 1.15rem; }


.image { width: 100%; }
a { color: #554cb7; text-decoration: none; background-color: transparent; }
.small-title .h1:after,


#theme-primary-about:after { background-color: #554cb7; bottom: 0; content: ""; height: 7px; left: 47%; position: absolute; width: 80px; }
.white { color: #fff; }
.fixed-top { top: 0; }
#menuToggle { display: block; position: relative; z-index: 1; -webkit-user-select: none; user-select: none; color: #fff; margin: 0.5rem 0 0.5rem 1rem; }
#menuToggle span:nth-last-child(2) { margin-bottom: 0px; }

#menuToggle a { text-decoration: none; color: #fff; font-family: "Oswald", sans-serif; font-size: 4.0625rem; font-weight: 700; transition: color 0.3s ease; }

#menuToggle a:hover { color: #17e5ac; }

#menuToggle input { display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; cursor: pointer; opacity: 0; /* hide this */ z-index: 2; /* and place it over the hamburger */ -webkit-touch-callout: none; }

/*
 * Just a quick hamburger
 */
#menuToggle span { display: block; width: 33px; height: 4px; margin-bottom: 5px; position: relative; background: #cdcdcd; border-radius: 3px; z-index: 1; transform-origin: 4px 0px; transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease; }

#menuToggle .darkSpan { display: block; width: 33px; height: 4px; margin-bottom: 5px; position: relative; background: #000; border-radius: 3px; z-index: 1; transform-origin: 4px 0px; transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease; }

#menuToggle span:first-child { transform-origin: 0% 0%; color: #fff; }

#menuToggle span:nth-last-child(2) { transform-origin: 0% 100%; color: #fff; }

#menuToggle input:checked ~ span { opacity: 1; transform: rotate(45deg) translate(-2px, -1px); color: #fff; }

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) { opacity: 0; transform: rotate(0deg) scale(0.2, 0.2); color: #fff; }

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) { transform: rotate(-45deg) translate(0, -1px); color: #fff; }

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu { position: absolute; max-width: 600px; width: 100vw; height: calc(100vh + 50px); top: -50px; padding: 100px 50px 50px 100px; background: #0b2d44; list-style-type: none; -webkit-font-smoothing: antialiased; transform-origin: 100% 0%; transform: translateX(calc(100% + 1rem)); transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1); margin-bottom: 0; }

#menu li { /* padding: 10px 0; */ font-size: 22px; }

#menuToggle input:checked ~ ul { transform: translateX(calc(-100% + 49px)); }
#menuToggle input:checked ~ .dark { background: white; }
.navbar-brand { display: inline-block; margin-top: 0.5rem; margin-bottom: 0.5rem; line-height: inherit; white-space: nowrap; }
.navbar-brand svg { height: auto; }

@media (max-width: 767.98px) {
  .navbar.fixed-top .navbar-brand { max-width: 125px; }
  .navbar.fixed-top .navbar-brand svg { width: 100%; }
  #menu { padding: 100px 30px 75px; }
}

.nav-shaky { float: right; z-index: 1500; /* margin-top: 40px; */ }
.hero-section { height: 100vh; min-height: 500px; background-size: cover; background-blend-mode: darken; }
header.full-height { height: 100vh; min-height: 500px; }
.hero-section-no-image .jumbotron { padding-bottom: 0; }



button .active { color: #000; opacity: 1; }
.btn { font-size: 0.95rem; letter-spacing: 1.5px; text-transform: uppercase; border-radius: 0; padding: 15px 20px; }
.btn-primary { color: #fff; background-color: #554cb7; border-color: #554cb7; }
.btn-primary:hover,
.btn-primary:focus { color: #fff; background-color: #473f9d; border-color: #473f9d; }
.btn-secondary { color: #fff; background-color: #37ebb9; border-color: #37ebb9; }
.btn-secondary:hover,
.btn-secondary:focus { color: #fff; background-color: #17e5ac; border-color: #17e5ac; }

.cta-btn-center { display: flex; justify-content: center; }
.second-section-text { margin: auto 0 auto 0; }
.grey-background { background-color: #f1f1f1; }
.contactBlock { padding: 250px 0 0; }

.contactBlockTitle { font-size: 48px; }

.contactBlockDetails { display: -webkit-flex; display: flex; width: 100%; -webkit-justify-content: center; justify-content: center; margin-bottom: 70px; }
.contactBlockDetails form { width: 100%; max-width: 700px; }
.contactForm { width: 50%; padding-right: 15px; }
form { display: block; margin-top: 0em; }
.contactFormSubtitle { font-size: 1.8rem; margin-bottom: 75px; }
.contactFormSubtitle p { font-size: 1.8rem; }
.contactFormSubtitleThankYou { width: 660px; font-size: 1.8rem; margin-bottom: 75px; }
.contactFormSubtitleThankYou p { font-size: 1.8rem; }

.contactFormRow { display: -webkit-flex; display: flex; -webkit-flex-direction: row; flex-direction: row; margin-left: -30px; margin-right: -30px; margin-bottom: 75px; }
.contactFormCol { -webkit-flex: 1 1; flex: 1 1; padding: 0 30px; }
.contactFormControl { width: 100%; border: none; padding: 15px 0; }
.form { width: 50%; padding-right: 15px; }
.contactFormTitle { font-size: 29px; margin-bottom: 75px; }

.contactFormControl:focus { outline: none; }
.contactFormBudget .contactFormRow:nth-child(1) { margin-bottom: 30px; }
.contactFormBudget .contactFormRow { margin-bottom: 20px; }
.contactFormBudgetLabel { color: #554cb7; background-color: #fff; border: 1px solid #554cb7; height: 50px; display: flex; justify-content: center; align-items: center; font-size: 15px; font-weight: 600; }
.contactFormBudgetInput { display: none; }
.contactFormBudgetInput:checked { background-color: #554cb7; color: #fff; }
.contactFormBudgetInput:hover { background-color: #554cb7; color: #fff; }
.contactFormButton-btn { background-color: secondary; border-color: secondary; }
.contactFormButton-btn:hover { background-color: darkerteal; border-color: darkerteal; }
.slidecontactBlockOfficesWrapper { width: 50%; padding-left: 95px; flex-shrink: 0; }

@media (max-width: 991px) {
  .slidecontactBlockOfficesWrapper { width: 100%; padding-left: 0; margin-top: 30px; }
}

.contactBlockOfficesWrapperListItem { display: flex; flex-direction: column; }
.contactBlockOfficeMedia { aspect-ratio: 4/3; position: relative; }
.contactBlockOfficeInfo { display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 15px; -webkit-justify-content: space-between; justify-content: space-between; }

@media (max-width: 767px) {
  .contactBlockOfficeInfo { display: grid; grid-template-columns: 1fr; grid-gap: 15px; -webkit-justify-content: space-between; justify-content: space-between; }
}

.contactBlockOfficeInfo { background-color: #f1f1f1; padding: 30px 40px; }
.contactBlockOfficeInfoBlocks { display: flex; flex-direction: column; text-align: center; }
.contactBlockOfficeInfoBlocks-icons { display: flex; justify-content: center; margin-top: 22px; }
.contactBlockOfficeInfoBlocksTitle { font-size: 16px; text-transform: uppercase; font-weight: 700; }
.contactBlockOfficeInfoBlocksText { margin-top: 22px; word-wrap: break-word; }
.offices-wrapper-arrows { background-color: #0b2d44; display: flex; justify-content: space-between; align-items: center; padding: 20px 120px; }

@media (max-width: 767px) {
  .offices-wrapper-arrows { padding: 20px; }
}

.offices-wrapper-arrows .carousel-control-prev,
.offices-wrapper-arrows .carousel-control-next { position: relative; }
.MuiFormControl-root { margin: 0; display: inline-flex; position: relative; min-width: 0; flex-direction: column; vertical-align: top; }

.MuiInputLabel-animated { transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms; }
.MuiInputLabel-formControl { top: 0; left: 0; position: absolute; transform: translate(0, 24px) scale(1); }
.MuiInput-underline:before { left: 0; right: 0; bottom: 0; content: "\00a0"; position: absolute; transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; border-bottom: 1px solid rgba(0, 0, 0, 0.42); pointer-events: none; }
.MuiInput-underline:after { left: 0; right: 0; bottom: 0; content: ""; position: absolute; transform: scaleX(0); transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms; border-bottom: 2px solid #3f51b5; pointer-events: none; }
.MuiInputLabel-root { display: block; transform-origin: top left; }
.MuiFormLabel-root { color: rgba(0, 0, 0, 0.54); padding: 0; font-size: 1rem; font-family: "Roboto", "Helvetica", "Arial", sans-serif; font-weight: 400; line-height: 1; letter-spacing: 0.00938em; }
.MuiFormLabel-root.shrink { transform: translate(0, 1.5px) scale(0.75); transform-origin: top left; color: #3f51b5; }
label { display: inline-block; margin-bottom: 0.5rem; }
label + .MuiInput-formControl { margin-top: 16px; }

.MuiInput-root { position: relative; }
.MuiInputBase-root { color: rgba(0, 0, 0, 0.87); cursor: text; display: inline-flex; position: relative; font-size: 1rem; box-sizing: border-box; align-items: center; font-family: "Roboto", "Helvetica", "Arial", sans-serif; font-weight: 400; line-height: 1.1876em; letter-spacing: 0.00938em; }
.MuiInputBase-input:invalid { box-shadow: none; }

.MuiInputBase-input { font: inherit; color: currentColor; width: 100%; border: 0; /*height: 1.1876em;*/ margin: 0; display: block; padding: 6px 0 7px; min-width: 0; background: none; box-sizing: content-box; animation-name: mui-auto-fill-cancel; letter-spacing: inherit; animation-duration: 10ms; -webkit-tap-highlight-color: transparent; }
button,
input { overflow: visible; }
button,
input,
optgroup,
select,
textarea { margin: 0; font-family: inherit; font-size: inherit; line-height: inherit; }
.card-intro { border: none; }
.card-intro .card-image { max-width: 100%; height: 145px; }
.card-intro .card-image img { top: 0; left: 0; bottom: 0; right: 0; box-sizing: border-box; padding: 0; border: none; margin: auto; display: block; max-width: 100%; height: 100%; max-height: 100%; }
.card-intro .card-footer { background-color: transparent; border-top: 0; }
.card-intro .card-title { font-size: 1.25rem; color: #707070; margin: 0; text-transform: uppercase; text-align: center; }
.bg-image { height: 100%; overflow: hidden; position: absolute; top: 0; width: 100%; }

.bg-image img { height: 100%; object-fit: cover; object-position: center center; width: 100%; }

.bg-image:before { background-color: rgba(0, 0, 0, 0.5); content: ""; height: 100%; position: absolute; width: 100%; z-index: 9; }

.text-center { text-align: center !important; }
.text-center:after { left: auto; margin-left: -40px; }

.bg-image:before { background-color: rgba(0, 0, 0, 0.5); content: ""; height: 100%; position: absolute; width: 100%; z-index: 9; }



p { margin-bottom: 30px; }



flex-center { align-items: center; display: flex; justify-content: center; }
text-shadow { text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); }
heading-separator { margin-bottom: 2.5rem; padding-bottom: 2.5rem; position: relative; }
heading-separator:after { background-color: gray-625; bottom: 0; content: ""; height: 7px; left: 0; position: absolute; width: 80px; }
.text-center:after { left: auto; margin-left: -40px; }
heading-separator.theme-primary:after { background-color: primary; }
heading-separator.theme-secondary:after { background-color: secondary; }
heading-separator.theme-dark:after { background-color: dark; }
/*.theme-primary {
  margin-top: 4rem;
  letter-spacing: 4px;
}*/

.btn > div > a { margin: 0 100% 20px 0; }
heading-separator.no-sep:after { content: none; }
.text-center heading-separator::after { right: 0; margin: 0 auto; }
.bg-gray-100 { background-color: #f1f1f1 !important; }
.small-title { font-size: 30px; }
/*.mt-5,
.my-5 { margin-top: 3rem !important; }*/
/*.row { display: -webkit-flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }*/

@media (min-width: 768px) {
  .offset-md-1 { margin-left: 8.3333333333%; }
}

/*@media (min-width: 768px) {
  .col-md-5 { -webkit-flex: 0 0 41.6666666667%; flex: 0 0 41.6666666667%; max-width: 41.6666666667%; }
}*/

.list-section-item { display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; margin-bottom: 30px; }
.list-section-item-number { color: #554cb7; font-weight: 800; font-family: "Oswald", sans-serif; font-size: 30px; margin-right: 30px; }
.list-section-item-title { font-weight: 800; }
.counter-section { padding: 100px 0; background-color: #004b6a; }
.counter-section-title { width: 100%; display: flex; justify-content: center; color: #fff; }
.counter-section-item { color: #fff; text-align: center; }
.counter-section-icons { display: flex; flex-wrap: wrap; justify-content: center; width: 100%; }
.counter-section-icons-item { color: rgb(255, 255, 255); text-align: center; width: 15%; margin: 40px 15px 0; }
.counter-section-icons-item-icon { position: relative; height: 100px; margin-bottom: 25px; display: flex; justify-content: center; align-items: center; }
.counter-section-icons-item-label { font-weight: 600; }
.counter-section-item-number { font-size: 80px; font-weight: 800; }
.counter-section-item-label { font-weight: 600; }


.overview-nav { list-style: none; padding: 0; display: -webkit-flex; display: flex; margin-bottom: 50px; -webkit-flex-wrap: wrap; flex-wrap: wrap; text-decoration: none; }
.overview-nav li { margin-right: 30px; font-family: "Oswald", sans-serif; font-size: 1.46rem; font-weight: 600; }
.overview-nav a { text-decoration: none; color: #000; text-transform: uppercase; }
.overview-nav li.active a { color: #554cb7; }
.case-overview-item a { color: #554cb7; text-decoration: none; background-color: transparent; }

.c-service-block-intro { position: relative; font-weight: 800; text-align: center; padding: 150px 0; }

@media only screen and (max-width: 576px) {
  .c-service-block-intro { padding: 50px 0; }
}

.c-service-block-title { position: absolute; position: absolute; transform: translate(-50%, -50%); top: 50%; left: 50%; font-size: 200px; color: #f1f1f1; z-index: -1; }
.c-service-block-title::after { content: none; }

@media only screen and (max-width: 576px) {
  .c-service-block-title { font-size: 50px; }
}

.c-service-block-list { padding: 0; list-style: none; text-align: center; font-weight: 500; font-family: "Oswald", sans-serif; }
.bg-gray-200 { background-color: #e9ecef !important; }
.bg-gray-300 { background-color: #dee2e6 !important; }

.hero-small { padding: 290px 0 100px; display: flex; flex-direction: column; align-items: center; }
.hero-small-subtext { font-size: 16px; text-transform: uppercase; margin-bottom: 18px; }
.hero-small-title { font-size: 48px; text-align: center; }

.hero-small-title:after { background-color: #554cb7; left: 50%; margin-left: -40px; }
.hero-small-title-block { padding: 100px 0 100px; display: flex; flex-direction: column; align-items: center; }

@media only screen and (max-width: 576px) {
  .hero-small-title-block { padding: 90px 0 40px; }
}

.hero-small-title-block-subtext { font-size: 16px; text-transform: uppercase; margin-bottom: 18px; }
.hero-small-title-block-title { font-size: 48px; text-align: center; }

@media only screen and (max-width: 576px) {
  .hero-small-title-block-title { font-size: 30px; }
}

.hero-small-title-block-title:after { background-color: #554cb7; left: 50%; margin-left: -40px; }

.double-list-section { padding: 100px 0; background-color: #f1f1f1; }
.listing-progress { margin: 100px 0; }
.listing-progress-title { margin-bottom: 30px; }
.listing-progress-label { text-align: right; }
.listing-progress-bars { background-color: #2b78999f; border-radius: 8px; }
.progress-bar { background-color: #0b2d44; }



@media (min-width: 992px) {
  .navbar.navbar-collapse { padding-top: 8.75rem; }
}

.navbar.navbar-collapse .navbar-nav { font-size: 4.0625rem; line-height: 1.308; font-family: "Oswald", sans-serif; font-weight: 700; text-align: right; text-transform: uppercase; }

.navbar-dark .nav-link.nav-shaky { background-color: #0b2d44; border-radius: 10px; }

.nav-link.nav-shaky { position: relative; }
.nav-link.nav-shaky:before { content: attr(data-hover); visibility: hidden; opacity: 0; width: 200px; background-color: #000000bf; color: #fff; text-align: center; border-radius: 5px; padding: 5px; transition: opacity 1s ease-in-out; position: absolute; z-index: 1; right: 0; top: 110%; }
.nav-link.nav-shaky:hover:before { opacity: 1; visibility: visible; }

.navbar-scrolled { background-color: #0b2d44; transition: 0.4s; }
.navbar-scrolled .navbar-brand { margin: 0; transition: 0.4s; }
.navbar-scrolled #menutoggle { transition: 0.4s; }
.navbar-scrolled #menu { /*top: -19px;*/ }
.navbar-scrolled-dark { color: white; transition: 0.4s; }
.navbar-scrolled-dark .navbar-brand svg g { fill: white; transition: 0.4s; }
.navbar-scrolled-dark #menutoggle .dark { background: white; transition: 0.4s; }

.navbar-nav { display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; padding-left: 0; margin-bottom: 0; list-style: none; }
.navbar.navbar-collapse { -webkit-align-items: center; align-items: center; background-color: #0b2d44; display: -webkit-flex !important; display: flex !important; height: 100% !important; overflow-y: auto; padding-top: 4.375rem; padding-bottom: 1rem; position: fixed; width: 100%; z-index: 99; right: -100%; -webkit-transition: -webkit-transform 0.35s; transition: -webkit-transform 0.35s; transition: transform 0.35s; transition: transform 0.35s, -webkit-transform 0.35s; -webkit-transform: translatex(0); transform: translatex(0); }



.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover { color: #fff; }
.navbar-dark #menuToggle span { background: rgb(11, 45, 68); }
.navbar-brand { display: inline-block; padding-top: 1rem; padding-bottom: 1rem; margin-right: 1rem; font-size: 1.25rem; line-height: inherit; white-space: nowrap; }

.bg-transparent { background-color: transparent !important; }

.switch { position: relative; /* display: inline-block; */ display: none; width: 60px; height: 20px; margin-bottom: 0px; z-index: 1500; }
.switch input { display: none; }

.slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #554cb7; -webkit-transition: 0.4s; transition: 0.4s; }

.slider:before { position: absolute; content: ""; height: 20px; width: 20px; background-color: white; -webkit-transition: 0.4s; transition: 0.4s; }

input:checked + .slider { background-color: #37ebb9; }

input:focus + .slider { box-shadow: 0 0 1px #2196f3; }

input:checked + .slider:before { -webkit-transform: translateX(45px); -ms-transform: translateX(45px); transform: translateX(45px); }

/*------ ADDED CSS ---------*/
.EN { display: none; }
.EN,
.NL { color: white !important; position: absolute; transform: translate(-50%, -50%); top: 50%; left: 50%; font-size: 10px; font-family: Verdana, sans-serif; }
.EN a,
.NL a { color: white; padding: 5px 25px; border-radius: 50%; }
input:checked + .slider .EN { display: block; }

input:checked + .slider .NL { display: none; }

/*--------- END --------*/

/* Rounded sliders */
.slider.round { border-radius: 34px; }

.slider.round:before { border-radius: 50%; }
/* contact block */

.MuiInputBase-input:focus { outline: none; }
.MuiInput-underline:focus:before { border-color: #3f51b5; }

.MuiInputBase-root { margin-top: 24px !important; }

.contactFormBudgetLabel:hover { background-color: #554cb7; color: white; }
.contactFormBudgetInput:checked + .contactFormBudgetLabel { background-color: #554cb7; color: white; }

.form-control { border: none; }
.form-control:focus { outline: none; }

/* navigation */
#navbar,
#navbar-dark { padding-top: 0; padding-bottom: 0; }
.navbar-links { display: flex; align-items: center; }

/* contact */
.contactBlockOfficeMedia { /*height: 100%;*/ }
.slidecontactBlockOfficesInner { display: flex; flex-direction: column; }

/* responsive */

@media (min-width: 768px) {
  #menutoggle a { font-size: 3.5rem; }
  .c-service-block-title { font-size: 125px; }
}

@media (max-width: 992px) {
  #menutoggle a { font-size: 2.5rem; }
  .expertise-item-content-wrapper { width: 100%; }
  .expertise-item-img-wrapper { width: 100%; }
  .c-service-block-title { font-size: 10rem; }
  .contactBlockDetails { flex-direction: column; align-items: center; }
  .contactBlockOfficesWrapper { width: 100%; padding: 0; }
}

@media (min-width: 992px) {
  #menutoggle a { font-size: 2.5rem; }
}

@media (max-width: 991.98px) {
  .hero-text h1 { line-height: 1.2304; }

  .counter-section-icons { flex-wrap: wrap; justify-content: center; }

  .counter-section-icons-item { width: 33.333%; margin-bottom: 50px; }

  .counter-section-icons-item-icon { height: 75px; margin-bottom: 5px; }
}

@media (max-width: 767.98px) {
  .jumbotron .btn { width: 100%; }
  .counter-section-icons-item { width: 50%; }
}

@media (max-width: 768px) {
  .contactFormRow { display: block; margin-bottom: 0px; }
  .contactBlockDetails input[type="submit"] { margin-top: 30px; }

  .no-scroll-y { touch-action: none; }

  .navbar-brand { margin-right: 0px; z-index: 50; }
  #menutoggle a { font-size: 2rem; text-align: right; }
  .card-deck .card { display: flex; align-items: center; }
  .card-deck { display: none !important; }
  .card-intro .card-image { width: 50%; }
  .cta-btn-center { width: fit-content; }
  .contactBlock { padding: 50px 0 0; }
  .contactBlockTitle { font-size: 1.8rem; }
  .blog-teaser-item { height: fit-content; }

  .c-service-block-title { /*position: relative;
    top: 0;
    left: 0;
    transform: inherit;*/ font-size: 4.5rem; }
  .contactBlockDetails { flex-direction: column; }
  .contactBlockOfficesWrapper { width: 100%; padding: 0; }
  .expertise-item { flex-direction: column; }
}

.rich-text img { max-width: 100%; margin: 75px auto; display: block; }





